html, body, #root, #app { 
  height: 100%; 
}

#SignIn {
  position: absolute;
  top: 5%;
  left: 5%;
}

#SignOut {
  position: absolute;
  bottom: 1%;
  right: 1%;
}
